import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: `${theme.palette.grey[900]}`,
        height: "100px",
        py: 3,
        px: 2,
      }}
    >
      <Typography
        sx={{ fontWeight: "300", opacity: "0.3" }}
        align="center"
        variant="body1"
        color="white"
      >
        Anderson Godz © 2023. Todos os direitos reservados. All rights reserved.
      </Typography>
    </Box>
  );
}
