import { Box, Button, Container, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import "./BlocoPrincipal.css";

export default function BlocoPrincipal() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[700]}`,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          objectFit: "cover",
          backgroundColor: "#1e273040",
          zIndex: 1,
        }}
      />

      <video
        id="either-gif-or-video"
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
          objectFit: "cover",
        }}
        src={`/video-anderson.mp4`}
      />
      {/* <Box
        sx={{
          background:
            "linear-gradient(50deg, #161c2460, #161c2460 100%), url(https://media.giphy.com/media/KgeH37niWPEoS8pLo0/giphy.gif) no-repeat 49% center",
          backgroundImage: "url(AndersonGodz.gif)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: {
            xs: "80vh",
            sm: "89vh",
            xl: "90vh",
          },
        }}
      >
        {/* /<VideoPlayer
    style={{ borderBottom: `1px solid ${theme.palette.grey[700]}` }}
    className="video"
    playsInline={true}
    src={VideoAnderson}
    autoPlay={true}
    muted={true}
   />
      </Box> */}

      <Box
        sx={{
          zIndex: 100,
          height: {
            xs: "80vh",
            sm: "91vh",
            xl: "90vh",
          },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            my: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                zIndex: "10",
                width: { xs: "100%", md: "80%", xl: "60%" },
                display: { xs: "flex", md: "block" },
                alignItems: { xs: "center", md: "start" },
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Typography
                align="left"
                color="primary"
                sx={{
                  /* color: "primary", */ fontWeight: 700,
                  opacity: 0.8,
                  textAlign: { xs: "center", md: "left" },
                }}
                variant="h4"
              >
                Anderson Godz
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: { xs: 30, sm: 45 },
                  fontWeight: "700",
                  color: "white",
                  lineHeight: 1.3,
                  mt: 2,
                  textAlign: { xs: "center", md: "left" },
                }}
                variant="h1"
              >
                {/* Expert brasileiro em <br />
                governança jovial */}
                Construindo o futuro da alta gestão ao lado de +1.500 líderes e
                conselheiros empresariais
              </Typography>
              {/* <Typography
                align="left"
                sx={{
                  color: "white",
                  opacity: 0.6,
                  mt: 2,
                  textAlign: { xs: "center", md: "left" },
                }}
                variant="h4"
              >
                Como alinhar poder, impacto, sócios e negócios na veloz
                economia? */}
              {/* <Typewriter
         options={{
          strings: ["Founder Gonew.Co", "Board Member", "Speaker & Author"],
          delay: [60],
          autoStart: true,
          loop: true,
         }}
        /> */}
              {/* </Typography> */}
              {/* <Typography align="center" sx={{ color: 'white' }} color="white" variant="h4">
                  Board Member | Founder Gonew.Co | GooDz Capital | Columnist l Speaker l Author l Advisor
                </Typography> */}
              <Button
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5541999317094"
                variant="outlined"
                size="large"
                sx={{
                  border: "1.5px solid #fd6b11",
                  alignSelf: "left",
                  fontWeight: 550,
                  letterSpacing: 1,
                  width: "fit-content",
                  transition: "0.2s",
                  px: 4,
                  py: 1,
                  mt: 3,
                  color: "white",
                  "&:hover": { transform: "scale(102%)" },
                }}
              >
                SAIBA MAIS
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

// background:
//   "linear-gradient(50deg, #161c2460, #161c2460 100%), url(https://media.giphy.com/media/KgeH37niWPEoS8pLo0/giphy.gif) no-repeat 49% center",
/* backgroundImage: "url(AndersonGodz.gif)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center", */
// backgroundSize: "cover",
/*  height: {
 xs: "80vh",
 sm: "89vh",
 xl: "90vh",
}, */

/* <VideoPlayer
    style={{ borderBottom: `1px solid ${theme.palette.grey[700]}` }}
    className="video"
    playsInline={true}
    src={VideoAnderson}
    autoPlay={true}
    muted={true}
   /> */
