import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";
/* import './Navbar.css'; */
import { Close, Menu } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useScrollSection } from "react-scroll-section";
import { varFadeIn as FadeIn } from "../theme/fadein";
import MotionInView from "../theme/MotionView";
import "./Navbar.css";
import Social from "./social";
//teste
export default function ButtonAppBar() {
  const theme = useTheme();

  const [getScroll, SetGetScroll] = useState();
  const [menuOpen, SetMenuOpen] = useState(false);

  function handleMenuOpen() {
    SetMenuOpen(!menuOpen);
  }

  useEffect(() => {
    window.addEventListener("scroll", function (event) {
      const scroll = this.scrollY;
      SetGetScroll(scroll);
    });
    // returned function will be called on component unmount
  }, []);

  const HomeSection = useScrollSection("Home");
  const BioSection = useScrollSection("Bio");
  const PalestrasSection = useScrollSection("Palestras");
  const EventosSection = useScrollSection("Eventos");
  const BooksSection = useScrollSection("Books");
  const NewsletterSection = useScrollSection("Newsletter");

  const items = [
    {
      onClick: HomeSection,
      selected: HomeSection,
      String: "Home",
      isFirst: true,
    },
    {
      onClick: BioSection,
      selected: BioSection,
      String: "Biografia",
      isFirst: false,
    },
    {
      onClick: BooksSection,
      selected: BooksSection,
      String: "E-books",
      isFirst: false,
    },
    {
      onClick: NewsletterSection,
      selected: NewsletterSection,
      String: "Conteúdo",
      isFirst: false,
    },
    {
      onClick: PalestrasSection,
      selected: PalestrasSection,
      String: "Palestras",
      isFirst: false,
    },
    {
      onClick: EventosSection,
      selected: EventosSection,
      String: "Eventos",
      isFirst: false,
    },
  ];

  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          boxShadow: "none",
          backgroundColor: `${theme.palette.grey[900]}`,
          borderBottom: `1px solid ${theme.palette.grey[500_16]}`,
          /*  ...(getScroll < 200
      ? { backgroundColor: `${theme.palette.grey[500_8]}` }
      : { backgroundColor: `${theme.palette.grey[900]}` }), */
          /*  ...(menuOpen && { backgroundColor: `${theme.palette.grey[900]}` }) */
        }}
        position="static"
      >
        <Toolbar>
          <IconButton
            onClick={handleMenuOpen}
            edge="start"
            sx={{
              pt: 1,
              pb: 0.5,
              size: 5,
              mt: 0.5,
              mr: 2,
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
              color: "white",
            }}
            aria-label="menu"
          >
            {menuOpen ? <Close /> : <Menu />}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: 90,
                height: 90,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src="/logo.png"
                alt="Anderson Godz"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                <ul className="menu">
                  {items.map((item, index) => (
                    <li
                      key={index}
                      onClick={item.onClick.onClick}
                      // selected={item.selected}
                      className="ItensMenu"
                    >
                      {item.String}
                    </li>
                  ))}
                </ul>
              </Box>
              <Social />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {menuOpen && (
        <MotionInView variants={FadeIn}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              pt: 2,
              backgroundColor: `${theme.palette.grey[900]}`,
            }}
          >
            {items.map((item, index) => (
              <MotionInView key={index} variants={FadeIn}>
                <Box
                  sx={{
                    m: 1.5,
                    background: `linear-gradient(90deg, ${theme.palette.grey[800]} 0%, ${theme.palette.grey[900]} 100%)`,
                    borderRadius: "2px",
                    "&:hover": {
                      borderLeft: `2px solid ${theme.palette.primary.main}`,
                    },
                    ...(item.isFirst
                      ? {
                          borderLeft: `2px solid ${theme.palette.primary.main}`,
                        }
                      : {}),
                  }}
                >
                  <Typography
                    onClick={() => {
                      item.onClick.onClick();
                      handleMenuOpen();
                    }}
                    selected={item.selected}
                    sx={{
                      color: "#ccc",
                      px: 1.5,
                      py: 1.5,
                    }}
                  >
                    {item.String}
                  </Typography>
                </Box>
              </MotionInView>
            ))}
            <Box /* sx={{ position: "relative", bottom: 0 }} */>
              <Link
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5541999317094"
              >
                <Typography
                  sx={{
                    ml: 1,
                    color: "#ccc",
                    textDecoration: "underline",
                    px: 1.5,
                    py: 1.5,
                  }}
                >
                  Entrar em contato
                </Typography>
              </Link>
              <Typography
                sx={{
                  ml: 1,
                  px: 1.5,
                  py: 0.5,
                  fontWeight: "300",
                  opacity: "0.3",
                }}
                variant="body1"
                color="white"
              >
                Anderson Godz © 2023. Todos os direitos reservados. All rights
                reserved.
              </Typography>
            </Box>
          </Box>
        </MotionInView>
      )}
    </div>
  );
}
