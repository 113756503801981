// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderStyle: 'Solid',
          borderColor: '#28333e',
          borderWidth: '1px',
          borderRadius: '12px',
          boxShadow: '0px 0px 7.4px rgba(0, 0, 0, 0.21)',
          filter: 'progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color="#000000") '
        }
      }
    }
  };
}
