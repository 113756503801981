import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useRef } from "react";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function Books() {
  const theme = useTheme();
  const carousel = useRef(null);

  function scrollRight(e) {
    e.preventDefault();
    carousel.current.scrollLeft += carousel.current.offsetWidth * items.length;
  }

  function scrollLeft(e) {
    e.preventDefault();
    carousel.current.scrollLeft -= carousel.current.offsetWidth * items.length;
  }

  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          py: "3rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
          overflow: "hidden",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ m: { xs: 0, sm: "0 auto" }, p: { xs: 0, sm: "0 auto" } }}
        >
          <Grid container>
            <Grid
              sx={{ mb: 0, px: { xs: "2rem", sm: "1rem" } }}
              item
              xs={12}
              md={12}
            >
              <Typography variant="h2" color="white">
                Livros
              </Typography>
              <Typography color="white" sx={{ opacity: 0.5 }} variant="body1">
                Acesse gratuitamente as publicações de autoria ou coautoria de
                Anderson Godz.
              </Typography>
            </Grid>

            <Box
              ref={carousel}
              sx={{
                display: "flex",
                gap: { sm: 3, xs: 1 },
                mt: 4,
                pl: { xs: "2rem", sm: "1rem" },
                overflow: { sm: "hidden", xs: "auto" },
                cursor: { sm: "default", xs: "pointer" },
                scrollBehavior: "smooth",
              }}
            >
              {items.map((item, index) => (
                <>
                  <MotionInView key={index} variants={FadeIn}>
                    <Card
                      sx={{
                        boxShadow: "none",
                        backgroundColor: `${theme.palette.grey[800]}`,
                        border: `1px solid ${theme.palette.grey[500_8]}`,
                        height: "100%",
                        "&:hover": {
                          border: `1px solid ${theme.palette.primary.main}`,
                          transition: "0.3s",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          padding: 2.7,
                          width: { sm: "350px", xs: "270px" },
                          overflowX: "auto",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={item.url}
                          sx={{ height: "6rem", width: "auto" }} // maybe fix later
                        />

                        <Typography variant="h6" color="white">
                          {item.title}
                        </Typography>
                        <Typography
                          color="#95a6b7"
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "4",
                            WebkitBoxOrient: "vertical ",
                            hyphens: "auto",
                          }}
                        >
                          {item.description}
                        </Typography>
                        <Button
                          sx={{
                            mt: 1.5,
                            color: "white",
                            boxShadow: "none",
                          }}
                          href={item.href}
                          target="blank"
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          + Informações
                        </Button>
                      </Box>
                    </Card>
                  </MotionInView>
                </>
              ))}
            </Box>
            <Box
              sx={{
                display: { sm: "flex", xs: "none" },
                gap: 2,
                pl: { xs: "2rem", sm: "1rem" },
                mt: 4,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button color="primary" size="medium" onClick={scrollLeft}>
                <ArrowLeft />
              </Button>

              <Button color="primary" size="medium" onClick={scrollRight}>
                <ArrowRight />
              </Button>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

const items = [
  {
    title: "Governança para PMEs e Empresas em Crescimento",
    url: "/static/ebooks/Mockup_GUIA_GOV_PMES_EMPRESAS_CRESCIMENTO_A.png",
    description:
      "A maioria dos negócios falha por desalinhamento entre sócios. Não é por erro no produto ou serviço. Não é porque a ideia inicial tinha um defeito ou não era adequada. Passa por uma governança básica.",
    href: "https://gonew.co/ebooks/guia-governanca-pmes?tag=anderson-website",
  },
  {
    title: "A Era Das Contradições",
    url: "/static/ebooks/Mockup_A_Era_das_Contradicoes.png",
    description:
      "Encurtamento dos ciclos de poder, a eclosão de uma guerra fria digital, Quinto e Sexto Poderes, a splinternet e o metaverso: esses e outros temas se mostram cada vez mais paradoxais. Este é um livro de reflexões profundas sobre avanços tecnológicos, seus paradoxos e a lembrança de que somos nós AINDA no controle! Ou no #SomeControl!",
    href: "https://gonew.co/ebooks/era-das-contradicoes?tag=anderson-website",
  },
  {
    title: "Governança & Nova Economia",
    url: "/static/ebooks/Mockup_GNE_3.png",
    description:
      "A governança corporativa, na prática, consiste nas regras que dão sentido e rotina ao jogo empresarial e devem conferir agilidade, autonomia e transparência aos jogadores. Contudo, a maioria das ferramentas de administração não está preparada para um ambiente de extrema incerteza.",
    href: "https://gonew.co/ebooks/governanca-nova-economia?tag=anderson-website",
  },
  {
    title: "Board Canvas",
    url: "/static/ebooks/Mockup_Board_Canvas_3.png",
    description:
      "A maioria dos negócios falha em razão do desalinhamento entre sócios. Por meio deste modelo, simplifique a governança para sua Pequena ou Média Empresa (PME) ou startup.",
    href: "https://gonew.co/ebooks/board-canvas?tag=anderson-website",
  },
  {
    title: "As Funções dos Novos Conselhos",
    url: "/static/ebooks/Mockup_FUNCOES_NOVOS_CONSELHOS.png",
    description:
      "Quais são os novos caminhos dos conselhos empresariais em um mundo que está em constante movimento? Novas tecnologias em rede e discussões sobre o uso de dados e privacidade, por exemplo, obrigam a reinvenção das empresas.",
    href: "https://gonew.co/ebooks/as-funcoes-dos-novos-conselhos?tag=anderson-website",
  },

  {
    title: "O Universo da Governança na Veloz Economia",
    url: "/static/ebooks/Mockup_Universo_NOVO.png",
    description:
      "Nesta obra elaboramos um guia e buscamos mapear as práticas existentes, entendendo que as aplicabilidades e circunstâncias são específicas da realidade de cada empresa, sociedade ou indivíduo. Aproveite a leitura.",
    href: "https://gonew.co/ebooks/o-universo-da-governanca-na-veloz-economia?tag=anderson-website",
  },
  {
    title: "Ética & Tecnologias",
    url: "/static/ebooks/Mockup_Etica.png",
    description:
      "Esta obra foi estruturada para tratar das questões que conectam Ética & Inovação, pensando em atender às perspectivas de interesses de organizações, sociedade e indivíduos. Acompanhe parte das rápidas mudanças que temos vivenciado.",
    href: "https://gonew.co/ebooks/etica-tecnologias?tag=anderson-website",
  },
];
