import {
  Box,
  CardMedia,
  Container,
  Button,
  Divider,
  Typography,
  Link,
} from "@material-ui/core";
import { display } from "@material-ui/core/node_modules/@material-ui/system";
import { useTheme } from "@material-ui/core/styles";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";
import StayTuned from "./StayTuned";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function Newsletter() {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          px: "2rem",
          pb: "3rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container sx={{ px: { xs: 0, sm: 1 } }} maxWidth="lg">
          <Box sx={{ mb: 0, pt: 8, pb: { xs: 2, sm: 6 } }}>
            <Typography
              sx={{ mb: 3, fontSize: { xs: 32, sm: 45 } }}
              align="left"
              color="white"
              variant="h2"
            >
              Conteúdo para sempre estar conectado!
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              py: "1.5rem",
            }}
          >
            <Box>
              <Typography
                color="white"
                sx={{
                  alignSelf: "center",
                  lineHeight: 1.5,
                }}
                variant="h3"
              >
                Newsletter
              </Typography>

              <Typography
                color="white"
                sx={{
                  alignSelf: "center",
                  lineHeight: 1.5,
                  opacity: 0.5,
                }}
                variant="body1"
              >
                Sua fonte semanal para melhores decisões digitais!
              </Typography>
              <Link href="https://community.gonew.co/daily" target="_blank">
                <CardMedia
                  component="img"
                  image="/static/newsletter/ipad.png"
                  sx={{
                    height: { xs: "25rem", sm: "30rem" },
                    width: "auto",
                    ml: "-1.5rem",
                    marginTop: "2rem",
                  }} // maybe fix later
                />
              </Link>

              <Button
                variant="outlined"
                href="https://community.gonew.co/daily"
                target="_blank"
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  py: "0.8rem",
                  px: "2.5rem",
                  mb: { xs: 6, sm: 3 },
                  cursor: "pointer",
                  transition: "0.5s",
                  "&:hover": {
                    border: `1px solid ${theme.palette.primary.main}`,
                    transform: "scale(103%)",
                  },
                }}
              >
                ACESSAR{" "}
              </Button>
            </Box>

            <Divider
              orientation="vertical"
              sx={{ display: { xs: "none", sm: "block" } }}
              flexItem
            />

            <Divider
              orientation="horizontal"
              sx={{ display: { xs: "block", sm: "none" } }}
              flexItem
            />

            <Box
              sx={{
                mt: { xs: 4, sm: 0 },
              }}
            >
              <Typography
                color="white"
                sx={{
                  alignSelf: "center",
                  lineHeight: 1.5,
                  ml: 2,
                }}
                variant="h3"
              >
                Podcast
              </Typography>

              <Typography
                color="white"
                sx={{
                  alignSelf: "center",
                  lineHeight: 1.5,
                  opacity: 0.5,
                  ml: 2,
                }}
                variant="body1"
              >
                Seus minutos de reflexão e informação sobre os desafios de
                conectar o mundo!
              </Typography>

              <StayTuned />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
