import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function FalamSobre() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          py: "3rem",
          px: "1rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "1rem", paddingBottom: "4rem" }}
        >
          <Typography variant="h2" color="white">
            O que falam sobre?
          </Typography>
          {/* <Typography color="white" sx={{ opacity: 0.5 }} variant="body1">
      Recentes
     </Typography> */}

          <Grid sx={{ pt: 5 }} align="left" container spacing={3}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={item.title}>
                <MotionInView key={index} variants={FadeIn}>
                  <Card
                    sx={{
                      /* maxWidth: 345, */
                      minHeight: 350,
                      boxShadow: "none",
                      backgroundColor: `${theme.palette.grey[800]}`,
                      border: `1px solid ${theme.palette.grey[500_8]}`,
                      height: "100%",
                      "&:hover": {
                        border: `1px solid ${theme.palette.primary.main}`,
                        transform: "scale(1.02)",
                        transition: "0.3s",
                      },
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <CardMedia
                          component="img"
                          image={item.imagem}
                          sx={{
                            border: `1px solid ${theme.palette.grey[500_8]}`,
                            height: "6rem",
                            width: "6rem",
                            borderRadius: "100%",
                          }}
                        />
                        <Box sx={{}}>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical ",
                              hyphens: "auto",
                            }}
                            variant="h6"
                            color="white"
                          >
                            {item.title}
                          </Typography>
                          <Typography color="#95a6b7" variant="body2" sx={{}}>
                            {item.subtitle}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ py: 1.5 }}>
                        <Divider fullWidth />
                      </Box>
                      <Typography
                        color="#95a6b7"
                        variant="body2"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "6",
                          WebkitBoxOrient: "vertical ",
                          hyphens: "auto",
                          pt: 1,
                        }}
                      >
                        {item.description}
                      </Typography>
                      {item.href !== "" && (
                        <Button
                          sx={{
                            textTransform: "uppercase",
                            mt: 2,
                            color: "white",
                            boxShadow: "none",
                          }}
                          href={item.href}
                          target="blank"
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          {item.buttonText}
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </MotionInView>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

const items = [
  {
    title: "Ricardo Amorim",
    subtitle:
      "Economista mais influente do Brasil e maior influenciador brasileiro no LinkedIn.",
    imagem: "/static/RicardoAmorim.jpg",
    description: `"Uma nau desgovernada se perde no mar. Com uma empresa sem governança acontece a mesma coisa. Neste livro, Anderson vai lhe ensinar como não deixar isto acontecer com a sua startup."`,
    href: "https://www.linkedin.com/in/ricardoamorimricam/",
    buttonText: "+ informações",
  },

  {
    title: "O Globo",
    subtitle:
      "Ferramenta gratuita da edtech Gonew.co vai avaliar maturidade...",
    imagem:
      "https://controlenamao.com.br/noticia/img/materias/20201116173507-aplicativo-de-pedidos-e-delivery-controlenamao-no-o-globo.png",
    description: `A Gonew.co, edtech de cursos sobre governança corporativa e inovação para líderes empresariais, está criando com a BB Tecnologia e Serviços, do Banco do Brasil, uma ferramenta on-line gratuita para pequenas e médias empresas avaliarem a maturidade do negócio. Será uma versão automatizada do Board Canvas, método desenvolvido pela start-up, e deve sair em dois meses. A ideia é ajudar a acelerar PMEs e impactar um milhão delas até dezembro de 2022.`,
    href: "https://blogs.oglobo.globo.com/pense-grande/post/ferramenta-gratuita-da-edtech-gonewco-vai-avaliar-maturidade-de-pequenas-e-medias-empresas.html",
    buttonText: "+ informações",
  },

  {
    title: "Vitor Torres",
    subtitle: "Founder na Contabilizei.",
    imagem: "/static/VitorTorres.png",
    description: `"Anderson foi extremamente feliz ao
apresentar os fundamentos e práticas de
mercado que visam a construção de modelos
de governança e compliance capazes de
acompanhar a evolução do negócio em seus
diferentes estágios de crescimento"`,
    href: "https://www.linkedin.com/in/vitordtorres/",
    buttonText: "+ informações",
  },

  {
    title: "Época Negócios",
    subtitle:
      "Coronavírus: pandemia acelerou demanda por programas de inovação.",
    imagem:
      "https://brilchamber.org.br/wp-content/uploads/2021/06/fb-thumb.png",
    description: `Com a crise econômica provocada pela pandemia de coronavírus, a Gonew.co, edtech de Curitiba que oferece capacitação em inovação para profissionais de alta gestão, viu a demanda de novos alunos crescer rapidamente. Com isso, a receita da startup dobrou em relação a 2019. No ano passado, a empresa saltou de R$ 1,5 milhão para R$ 3 milhões em faturamento. Para 2021, a expectativa da edtech é chegar à casa dos R$ 9 milhões.`,
    href: "https://epocanegocios.globo.com/Podcast/Negnews/noticia/2021/04/coronavirus-pandemia-acelerou-demanda-por-programas-de-inovacao-para-executivos.html",
    buttonText: "+ informações",
  },

  {
    title: "Gazeta do Povo",
    subtitle: "Comunidade GoNew oferece mentoria gratuita para startups e PMEs",
    imagem: "/static/GazetaDoPovo.png",
    description: `Estão abertas as inscrições de startups e pequenas e médias empresas para uma mentoria gratuita de 40 horas oferecidas pelo programa C2i – Programa de Certificação para Conselheiro de Inovação, que integra a Comunidade Governança & Nova Economia (Gonew.co). São 35 conselheiros que já atuam como C-Levels, diretores e investidores, entre outras posições de destaque, em empresas já consolidadas no Brasil.`,
    href: "https://www.gazetadopovo.com.br/gazz-conecta/breves/comunidade-gonew-oferece-mentoria-gratuita-para-startups-e-pmes/",
    buttonText: "+ informações",
  },
];
