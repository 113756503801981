import { createTheme } from "@material-ui/core/styles";
import palette from "./palette";
import typography from "./typography";
import shape from "./shape";
import paper from "./Paper";
import dialog from "./dialog";
import breakpoints from "./breakpoints";

export const theme = createTheme({
 palette,
 typography,
 breakpoints,
 dialog,
 paper,
 shape,
});
