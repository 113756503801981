/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PlayCircle from "@material-ui/icons/PlayCircleFilled";
import axios from "axios";
// material
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Stack,
  Link,
} from "@material-ui/core";

import CardMedia from "@material-ui/core/CardMedia";
import xml2js from "xml2js";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(() => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "700px",
  marginTop: "1rem",
}));

// ----------------------------------------------------------------------

StayTuned.propTypes = {
  displayName: PropTypes.string,
};

export default function StayTuned() {
  /* const [items, setItems] = useState([]);

  useEffect(() => {
    axios
      .get("https://anchor.fm/s/4e022ff8/podcast/rss", {
        headers: { "Content-Type": "text/xml" },
      })
      .then((result) => {
        const parser = new xml2js.Parser();
        parser.parseString(result.data, (err, result) => {
          setItems(result.rss.channel[0].item?.slice(0, 2));
        });
      });
  }, []); */

  const items = [
    {
      title: "Cafézinho da Governança com Celso Ienaga",
      description:
        "Os empresários e os conselhos estão preparados para as exigências da nova economia em um mundo cada vez mais digital?",
      link: "https://www.youtube.com/watch?v=AZZ0ymk5mP0&ab_channel=Gonew",
      image: "/static/cafezinho.png",
    },
    {
      title: "Fábio Póvoa e Vivian Muniz",
      description:
        "Qual o ponto de convergência entre investidores e founders?",
      link: "https://www.youtube.com/watch?v=__WeNDJoaEY&ab_channel=Gonew",
      image: "/static/podcast2.png",
    },
  ];

  return (
    <RootStyle>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          margin: 0,
        }}
      >
        <Stack container>
          {items.map((item, index) => (
            <Cards
              length={items.length}
              key={item.title}
              index={index}
              item={item}
            />
          ))}
        </Stack>
      </CardContent>
    </RootStyle>
  );
}

function Cards({ item, length, index }) {
  const theme = useTheme();

  return (
    <Link href={item.link} target="_blank" underline="none">
      <Box sx={{ height: "100%", display: "block", py: 1.5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 2,
            borderBottom: index < length - 1 ? "solid #919eab3d 1px" : "none",
            paddingTop:
              index === length - 1 ? (theme) => theme.spacing(3) : "0px",
            paddingBottom:
              index < length - 1 ? (theme) => theme.spacing(6) : "0px",
          }}
        >
          <Box
            sx={{
              /* width: '25%', */
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardMedia
              sx={{ borderRadius: "7px", width: { xs: "120px", md: "150px" } }}
              component="img"
              image={item.image}
              /* width="100px" */
              /* image={item["itunes:image"][0].$.href} */
              alt="imagem com o nome do curso"
            />
          </Box>

          <Box
            flexGrow={1}
            sx={{
              paddingRight: { xs: theme.spacing(0), sm: theme.spacing(0) },
              paddingLeft: { xs: theme.spacing(2), sm: theme.spacing(3) },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <Typography variant="body2" sx={{ color: "#fff" }}>
              {new Date(item.pubDate).toLocaleDateString()}
            </Typography> */}
            <Typography
              variant="body2"
              sx={{ color: "#fff", fontWeight: "bold" }}
            >
              EPISÓDIO DE
            </Typography>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingTop: "0.4rem",
                fontWeight: "bold",
                fontSize: "1rem",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical ",
                color: "#fff",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical ",
                color: "#fff",
              }}
            >
              {item.description}
            </Typography>
            {/*  */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <IconButton
                onClick={() => window.open(item.link)}
                color="primary"
                aria-label="Play"
                component="span"
              >
                <PlayCircle />
              </IconButton>
            </div>
          </Box>
        </Box>
      </Box>
    </Link>
  );
}
