import { Box, Container, Grid, Stack, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Social from "../../components/social";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function Bio() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          py: "4rem",
          px: "1rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <MotionInView variants={FadeIn}>
            <Box sx={{}}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: "flex", justifyContent: "start" }}>
                    <img
                      height={400}
                      src="/static/AndersonGodz.png"
                      alt="Anderson Godz"
                    />
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                  xs={12}
                  sm={8}
                >
                  <Box sx={{ alignSelf: "center" }}>
                    <Stack /* spacing={2} */>
                      <Typography color="white" variant="h2">
                        Anderson Godz
                      </Typography>
                      <Typography
                        color="primary"
                        variant="h5"
                        sx={{ opacity: 0.8, mb: 3 }}
                      >
                        Empreendedor, Conselheiro, Investidor, Escritor e
                        Palestrante. Une lições de mais de 30 anos em três
                        ciclos profissionais bem diferentes.
                      </Typography>
                      <Typography
                        color="white"
                        variant="body1"
                        sx={{ lineHeight: 2, opacity: 0.3, fontSize: "1rem" }}
                      >
                        De 1992 a 2009 liderou transformações em grandes
                        empresas. Entre 2010 e 2016 criou e vendeu uma pequena
                        empresa e as lições se tornaram o premiado método
                        “Canvas de Sócios”.
                        <br />
                        Desde então, é Conselheiro de Administração, investe em
                        mais de 30 empresas e criou dezenas de negócios de
                        sucesso e insucesso (!) com mais de 150 sócios
                        diferentes.
                        <br />É também autor e co-autor de 10 livros,
                        Administrador e Mestre em Governança e Sustentabilidade.
                        Capacitou e aprendeu com mais de 1.500 líderes de alta
                        gestão e conselhos empresariais ao fundar a Comunidade
                        Gonew, que conta com 26 mil participantes.
                      </Typography>
                    </Stack>
                    <Box sx={{ ml: -1, mt: 1 }}>
                      <Social />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </MotionInView>
        </Container>
      </Box>
    </>
  );
}
