import { alpha } from "@material-ui/core/styles";

const GREY = {
 0: "#FFFFFF",
 100: "#F9FAFB",
 200: "#1E2730",
 300: "#DFE3E8",
 400: "#C4CDD5",
 500: "#919EAB",
 600: "#637381",
 700: "#454F5B",
 800: "#212B36",
 900: "#161C24",
 500_8: alpha("#919EAB", 0.08),
 500_12: alpha("#919EAB", 0.12),
 500_16: alpha("#919EAB", 0.16),
 500_24: alpha("#919EAB", 0.24),
 500_32: alpha("#919EAB", 0.32),
 500_48: alpha("#919EAB", 0.48),
 500_56: alpha("#919EAB", 0.56),
 500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
 lighter: "#C8FACD",
 light: "#5BE584",
 main: "#fd6b11",
 /*  main: "#fab61b", */
 dark: "#fd6b11ea",
 darker: "#005249",
 contrastText: "#121214",
};
const SECONDARY = {
 lighter: "#D6E4FF",
 light: "#84A9FF",
 main: "#070d23e7",
 dark: "#1939B7",
 darker: "#091A7A",
 contrastText: "#fff",
};
const INFO = {
 lighter: "#D0F2FF",
 light: "#74CAFF",
 main: "#1890FF",
 dark: "#0C53B7",
 darker: "#04297A",
 contrastText: "#fff",
};
const SUCCESS = {
 lighter: "#E9FCD4",
 light: "#AAF27F",
 main: "#54D62C",
 dark: "#229A16",
 darker: "#08660D",
 contrastText: GREY[800],
};
const WARNING = {
 lighter: "#FFF7CD",
 light: "#FFE16A",
 main: "#FFC107",
 dark: "#B78103",
 darker: "#7A4F01",
 contrastText: GREY[800],
};
const ERROR = {
 lighter: "#FFE7D9",
 light: "#FFA48D",
 main: "#FF4842",
 dark: "#B72136",
 darker: "#7A0C2E",
 contrastText: "#fff",
};

const palette = {
 common: { black: "#000", white: "#fff" },
 primary: { ...PRIMARY },
 secondary: { ...SECONDARY },
 info: { ...INFO },
 success: { ...SUCCESS },
 warning: { ...WARNING },
 error: { ...ERROR },
 grey: GREY,
 divider: GREY[500_24],
 action: {
  hover: GREY[500_8],
  selected: GREY[500_16],
  disabled: GREY[500_80],
  disabledBackground: GREY[500_24],
  focus: GREY[500_24],
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
 },
};

export default palette;
