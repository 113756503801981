import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function Artigos() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          py: "3rem",
          px: "1rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "1rem", paddingBottom: "4rem" }}
        >
          <Typography variant="h2" color="white">
            Artigos
          </Typography>
          <Typography color="white" sx={{ opacity: 0.5 }} variant="body1">
            Recentes
          </Typography>

          <Grid sx={{ pt: 5 }} align="left" container spacing={3}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={item.title}>
                <MotionInView key={index} variants={FadeIn}>
                  <Card
                    sx={{
                      /* maxWidth: 345, */
                      minHeight: 350,
                      boxShadow: "none",
                      backgroundColor: `${theme.palette.grey[800]}`,
                      border: `1px solid ${theme.palette.grey[500_8]}`,
                      height: "100%",
                      "&:hover": {
                        border: `1px solid ${theme.palette.primary.main}`,
                        transform: "scale(1.02)",
                        transition: "0.3s",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={item.imagem}
                      sx={{ height: "10rem" }} // maybe fix later
                    />
                    <CardContent>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical ",
                          hyphens: "auto",
                        }}
                        variant="h6"
                        color="white"
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="#95a6b7"
                        variant="body2"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "4",
                          WebkitBoxOrient: "vertical ",
                          hyphens: "auto",
                          pt: 1,
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Button
                        sx={{ mt: 2, color: "white", boxShadow: "none" }}
                        href={item.href}
                        target="blank"
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        ACESSAR
                      </Button>
                    </CardContent>
                  </Card>
                </MotionInView>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

const items = [
  {
    title: "Os novos poderes no mundo",
    imagem: "/static/osnovospoderesdomundo.png",
    description:
      "Tradicionalmente, a ideia de poder está fortemente vinculada a uma busca por monopólio político, militar, econômico ou decisório. No campo da Sociologia, tendo Max Weber como principal referência, o poder costuma ser encarado como a habilidade da imposição de vontade sobre os outros, ainda que haja resistência.",
    href: "https://www.linkedin.com/pulse/os-novos-poderes-mundo-anderson-godz/?originalSubdomain=pt",
  },
  /* {
  title: "O exponencial – e seus investidores – destroem o capitalismo",
  imagem:
   "https://media.gazetadopovo.com.br/2020/12/08170912/austin-distel-DfjJMVhwH_8-unsplash-1-960x540.jpg",
  description:
   "Os investidores de risco estão destruindo o capitalismo. A polêmica afirmação foi feita recentemente pelo jornalista de economia Charles Duhigg, egresso da Harvard Business School, em texto para a revista New Yorker. Segundo ele, mesmo a startup que tenha um desempenho horrendo poderá se sobressair em relação a concorrentes se houver investidores dispostos a ajudá-la. Para justificar sua tese, Duhigg cita como exemplo a WeWork, especializada em escritórios compartilhados, cujos altos e constantes investimentos permitiram que a empresa “cometesse um erro selvagem após o outro”, não evitando um fiasco",
  href:
   "https://www.gazetadopovo.com.br/vozes/advisors/o-exponencial-e-seus-investidores-destroem-o-capitalismo/",
 }, */
  {
    title: "Day Asset: o ativo mais importante de negócios da nova economia",
    imagem:
      "https://5babb5ad6f5f727e3e4bf9e1.static-01.com/l/images/2d50443d0abcab0c3b1edcb7aef6faa6e2fe7138.jpg",
    description: `Anda meio fora de moda possuir certos tipos de ativos em tempos de economia colaborativa.
Uma das maiores empresas do mundo com essa pegada, a Airbnb, criada em 2008, já vale mais de 30 bilhões de dólares. Ela nos fez rediscutir "o apartamento na praia", mas não pela trivial questão de deixar o "dinheiro parado", pois para isto bastaria começar a usar o Booking.com.`,
    href: "https://bit.ly/ag-artigo-day-asset",
  },
];
