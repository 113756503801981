import { IconButton } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
export default function social() {
 return (
  <>
   <IconButton
    target="_blank"
    href="https://www.linkedin.com/in/andersonrobertog/"
    aria-label="delete"
   >
    <LinkedInIcon
     sx={{
      color: "#ccc",
      "&:hover": { color: (theme) => theme.palette.primary.main },
     }}
     fontSize="medium"
    />
   </IconButton>

   <IconButton
    aria-label="delete"
    target="_blank"
    href="https://www.instagram.com/anderson.godz/"
   >
    <InstagramIcon
     sx={{
      color: "#ccc",
      "&:hover": { color: (theme) => theme.palette.primary.main },
     }}
     fontSize="medium"
    />
   </IconButton>

   <IconButton
    aria-label="delete"
    target="_blank"
    href="https://twitter.com/andersongodz"
   >
    <TwitterIcon
     sx={{
      color: "#ccc",
      "&:hover": { color: (theme) => theme.palette.primary.main },
     }}
     fontSize="medium"
    />
   </IconButton>
  </>
 );
}
