import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { useState } from "react";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";
import PopUpVideo from "./PopUpVideo";

export default function Palestras() {
  const [open, setOpen] = useState(false);
  const [videoUrl, setvideoUrl] = useState({});

  function handleOpen() {
    setOpen(true);
  }
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          /*    pt: "4rem",
     pb: "2rem", */
          px: "2rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container sx={{ px: { xs: 0, sm: 1 } }} maxWidth="lg">
          <Box sx={{ mb: 0, pt: 8, pb: 6 }}>
            <Typography
              sx={{ mb: 3, fontSize: { xs: 32, sm: 45 } }}
              align="left"
              color="white"
              variant="h2"
            >
              Principais Palestras, Workshops e Talks
            </Typography>
            <PopUpVideo
              open={open}
              setOpen={setOpen}
              videoUrl={videoUrl}
              setvideoUrl={setvideoUrl}
            />
            {palestras.map((item, index) => (
              <>
                <MotionInView key={index} variants={FadeIn}>
                  <Grid sx={{ flexDirection: "row" }}>
                    <Box
                      sx={{
                        display: "flex",
                        py: 0.5,
                        transition: "0.5s",
                        "&:hover": { transform: "scale(103%)" },
                      }}
                    >
                      <Typography
                        color="primary"
                        sx={{
                          alignSelf: "center",
                          mr: 2,
                          opacity: 1,
                          lineHeight: 2,
                        }}
                        variant="h2"
                      >
                        {item.number}
                      </Typography>

                      <Typography
                        color="white"
                        sx={{
                          alignSelf: "center",
                          mr: 2,
                          opacity: 0.8,
                          lineHeight: 1.5,
                        }}
                        variant="h5"
                      >
                        {item.description}
                      </Typography>
                      {item.videoUrl && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <SlowMotionVideoIcon
                            onClick={() => (
                              setvideoUrl(item.videoUrl), handleOpen()
                            )}
                            fontSize="large"
                            size="big"
                            color="primary"
                            sx={{
                              cursor: "pointer",
                              transition: "0.2s",
                              "&:hover": { transform: "scale(107%)" },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Divider />
                  </Grid>
                </MotionInView>
              </>
            ))}
            <Button
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5541999317094"
              variant="outlined"
              size="large"
              sx={{
                alignSelf: "left",
                width: "fit-content",
                transition: "0.2s",
                px: 4,
                py: 1,
                mt: 3,
                color: "white",
                "&:hover": { transform: "scale(102%)" },
              }}
            >
              CONTATO
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

const palestras = [
  {
    number: "01",
    description: "Os Seis Poderes na sociedade",
    videoUrl: "https://www.youtube.com/watch?v=NZf9pKAjRZI",
  },
  /* {
    number: "02",
    description: "As empresas e as contradições de conectar o mundo",
  }, */
  {
    number: "02",
    description: "Hiperexposição, mobilizações digitais e riscos reputacionais",
  },
  {
    number: "03",
    description: "Day Asset (Não se faz mais valuations como antigamente)",
  },
  /* {
    number: "05",
    description: "Os 10 fatores de pressão da nova economia na governança",
  }, */
  {
    number: "04",
    description: "Empresas velozes. Sócios furiosos?",
    videoUrl: "https://www.youtube.com/watch?v=0G_fh_vews4",
  },
];
