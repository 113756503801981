import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CardMedia,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Social from "../../components/social";
import { varFadeIn as FadeIn } from "../../theme/fadein";
import MotionInView from "../../theme/MotionView";
import "./Animation.css";

/* import { useTheme } from '@material-ui/core/sxs'; */

export default function LivroAcademiaDeSocios() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.grey[700]}`,
          m: 0,
          py: "4rem",
          px: "2rem",
          height: "auto",
          backgroundColor: `${theme.palette.grey[200]}`,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <MotionInView variants={FadeIn}>
            <Box>
              <Grid container>
                {items.map((item, index) => (
                  <>
                    <Grid item xs={12} sm={4}>
                      <Box
                        className="efeito-flutuante"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: { xs: 4, sm: 0 },
                        }}
                      >
                        <img
                          style={{
                            filter: `drop-shadow(0px 10px 30px ${theme.palette.grey[700]}80)`,
                          }}
                          height={220}
                          src={item.url}
                          alt="Livro"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      item
                      xs={12}
                      sm={8}
                    >
                      {/* <Typography
            variant="h4"
            color="primary"
            sx={{
             mb: 1,
             fontWeight: "bold",
             alignSelf: { xs: "center", sm: "start" },
            }}
           >
            LANÇAMENTO!
           </Typography> */}
                      <Typography
                        variant="h4"
                        color="white"
                        sx={{
                          fontWeight: "bold",
                          alignSelf: { xs: "center", sm: "start" },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="#95a6b7"
                        variant="body2"
                        sx={{
                          padding: {
                            xs: theme.spacing(2, 0),
                            sm: theme.spacing(0, 0),
                          },
                          textAlign: { xs: "center", sm: "left" },
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "10",
                          WebkitBoxOrient: "vertical ",
                          hyphens: "auto",
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Button
                        sx={{
                          textTransform: "uppercase",
                          mt: 1.5,
                          color: "white",
                          boxShadow: "none",
                          width: "fit-content",
                          /*  py: 1,
             px: 2, */
                          alignSelf: { xs: "center", sm: "start" },
                        }}
                        href={item.href}
                        target="blank"
                        variant="outlined"
                        color="primary"
                      >
                        Download gratuito PDF
                      </Button>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </MotionInView>
        </Container>
      </Box>
    </>
  );
}

const items = [
  {
    title: "Livro Academia de Sócios",
    url: "/static/ebooks/ebookacademia.png",
    description:
      "Guia prático para (re) construir relações entre sócios, famílias empresárias e parceiros em negócios.",
    href: "https://community.gonew.co/impact/ebook-academia-de-socios-859",
    /*  href: "https://bit.ly/anderson-godz-popup-era-das-contradicoes", */
  },
];
