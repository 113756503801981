/* const DISTANCE = 120; */

const TRANSITION_ENTER = {
 duration: 0.9,
 ease: [0.43, 0.13, 0.23, 0.96],
};
export const TRANSITION_EXIT = {
 duration: 0.9,
 ease: [0.43, 0.13, 0.23, 0.96],
};

export const varFadeIn = {
 initial: { opacity: 0 },
 animate: { opacity: 1, transition: TRANSITION_ENTER },
 exit: { opacity: 0, transition: TRANSITION_EXIT },
};
