import {
 Box,
 Button,
 Card,
 CardContent,
 CardMedia,
 Dialog,
 DialogContent,
 Grid,
 Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";

export default function PopUpVideo({ open, setOpen, videoUrl, setvideoUrl }) {
 const theme = useTheme();
 const wasOpenedRef = useRef(false);
 const [wasOpened, setWasOpened] = useState(false);

 function handleClose() {
  setOpen(false);
  setvideoUrl({});
 }

 return (
  <>
   <Box
    sx={{
     width: "100%",
     height: "100%",
     position: "fixed",
     left: 0,
     top: 0,
     zIndex: 100,
     backgroundColor: `${theme.palette.grey[800]}60`,
     display: open ? "flex" : "none",
     justifyContent: "center",
     alignItems: "center",
    }}
    open={open}
    onClose={handleClose}
    onClick={handleClose}
   />
   <Box
    sx={{
     position: "fixed",
     display: open ? "flex" : "none",
     top: "50%",
     left: "50%",
     flexDirection: "column",
     transform: "translate(-50%, -50%)",

     backgroundColor: `${theme.palette.grey[800]}`,
     border: `1px solid ${theme.palette.grey[500_16]}`,
     p: { xs: 2, sm: 3 },
     borderRadius: "0.5rem",
     width: { xs: "99%", sm: "90%" },
     maxWidth: "lg",
     zIndex: 300,
     boxShadow: "0px 0px 1000px rgba(0, 0, 0, 10)",
    }}
   >
    <Box
     sx={{
      display: "flex",
      p: 0,
      position: "relative",
      paddingTop: "52.6%",
      width: "100%",
     }}
    >
     <ReactPlayer
      style={{
       position: "absolute",
       top: "0",
       left: "0",
       borderRadius: "0.5rem",
       overflow: "hidden",
      }}
      width="100%"
      height="100%"
      url={videoUrl}
      controls
      pip
      playing={open}
     />
    </Box>

    <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
     <Button variant="outlined" color="primary" onClick={handleClose}>
      Fechar
     </Button>
    </Box>
   </Box>
  </>
 );
}
