import { ScrollingProvider, Section } from "react-scroll-section";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Artigos from "./Artigos";
import Bio from "./Bio";
import BlocoPrincipal from "./BlocoPrincipal";
import Books from "./Books";
import EraDasContradicoes from "./EraDasContradicoes";
import FalamSobre from "./FalamSobre";
import "./Index.css";
import Newsletter from "./Newsletter";
import Palestras from "./Palestras";
import LivroAcademiaDeSocios from "./LivroAcademiaDeSocios";

export default function Home() {
  return (
    <>
      <ScrollingProvider>
        <div className="sticky">
          <Section id="Home">
            <Navbar />
          </Section>
        </div>
        <div id="Conteudo">
          <BlocoPrincipal />
          {/* <Section id="Manifesto">
            <Manifesto />
          </Section> */}

          <Section id="EraDasContradicoes">
            <LivroAcademiaDeSocios />
          </Section>

          <Section id="Bio">
            <Bio />
          </Section>

          <Section id="Books">
            <Books />
          </Section>

          <Section id="Newsletter">
            <Newsletter />
          </Section>

          <Section id="Palestras">
            <Palestras />
          </Section>

          <Section id="Artigos">
            <Artigos />
          </Section>

          {/* <Section id="Eventos">
      <Eventos />
     </Section> */}

          <Section id="FalamSobre">
            <FalamSobre />
          </Section>
          {/* <PopUp /> */}
        </div>
        <div>
          <Footer />
        </div>
      </ScrollingProvider>
    </>
  );
}
