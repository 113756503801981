import Routes from "./Routes";
import { theme } from "./theme/index";
import { ThemeProvider } from "@material-ui/core/styles";

function App() {
  return (
    <div
      style={{
        backgroundColor: "#121214",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
